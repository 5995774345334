<template>
  <div class="data-box">
    <van-nav-bar
        title="车辆清洁"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      活动期间请定期核查车辆清洗情况
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">消费车辆</div>
          <div class="span-input" @click="editCarPlate">
            <span>{{carPlate ? carPlate : '请选择车辆牌号'}}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item" v-for="(item,index) in carInfoKeyValueList" :key="index">
          <div class="span-key span-line">{{ item.key }}</div>
          <div class="span-value">{{ item.value ? item.value : "无" }}</div>
        </div>
      </div>
      <div class="body-div" v-show="carUseRecord.length > 0">
        <div class="body-div-title">
          使用记录
        </div>
        <div v-for="(item,index) in carUseRecord" :key="index">
          <div class="body-div-card">
            <span class="span1 align-left">{{item.roomEnergyFood}}</span>
            <span class="span1 align-right">{{'￥' + (item.num1 ? item.num1 : 0).toFixed(2)}}</span>
            <span class="span2 align-left">{{item.createTime}}</span>
            <span class="span2 align-right">{{ item.handlePerson }}</span>
          </div>
          <div class="dashed-line" v-show="index != carUseRecord.length - 1"></div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="showCode">车辆清洁码</van-button>
    </div>
    <van-action-sheet v-model="showCarPlate" :actions="carPlateActions" @select="selectCarPlate"/>
    <van-dialog v-model="showDialog" title="请出示二维码给商家扫码" confirm-button-text="关闭">
      <div class="align-center">
        <vue-qr :text="codeUrl" :size="200"></vue-qr>
      </div>
    </van-dialog>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import vueQr from "vue-qr";
import {
  actCarObjPage,
  selectCarShow,
  getWxCode
} from "@/api/index";

export default {
  name: "orgClear",
  data() {
    return {
      carPlate: "",
      showCarPlate: false,
      carPlateActions: [],
      carInfoKeyValueList: [
        {key: "车型名称",value: ""},
        {key: "消费周期",value: ""},
        {key: "消费备注",value: ""},
        {key: "单次限额",value: ""},
        {key: "提供场地",value: ""},
      ],
      carUseRecord: [],
      showDialog: false,
      codeUrl: ""
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
    vueQr
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getWxCode();
      that.getCarInfoList();
      if(that.$store.getters.getSelectClearCarId){
        that.getSelectCarInfo(that.$store.getters.getSelectClearCarId);
      }
    },
    //获取车辆信息列表
    getCarInfoList(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        pageNum: 1,
        pageSize: 9999
      };
      actCarObjPage(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.carPlateActions = res.data.records;
          that.carPlateActions.forEach(item => {
            item.name = item.noteNameDateStop;
          })
        })
      })
    },
    //获取当前选中车辆信息
    getSelectCarInfo(id){
      let that = this;
      let params = {
        ayId: id,
        type: 6
      }
      selectCarShow(params).then(res => {
        that.$tool.resValidation(res,() => {
          let data = res.data;
          data.carInfo = data.carInfo ? data.carInfo : [];
          data.carInfo.forEach(item => {
            if(item.relateType === 13){
              that.carPlate = item.noteNameDateStop;
              that.carInfoKeyValueList[0].value = item.checkTypeNumArticle;
            }
            if(item.relateType === 6){
              that.carInfoKeyValueList[2].value = item.noteNameDateStop;
              that.carInfoKeyValueList[3].value = item.checkTypeNumArticle + "元/次";
              that.carInfoKeyValueList[4].value = item.chargeName;
            }
          })
          that.carInfoKeyValueList[1].value = data.consumeDate.actStartDate + " ~ " + data.consumeDate.actEndDate;
          that.carUseRecord = data.carUseInfo ?data.carUseInfo : [];
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editCarPlate(){
      let that = this;
      that.showCarPlate = true;
    },
    selectCarPlate(item){
      let that = this;
      that.getSelectCarInfo(item.activityId);
      that.$store.commit('setSelectClearCarId', item.activityId);
      that.showCarPlate = false;
    },
    showCode(){
      let that = this;
      that.showDialog = true;
    },
    getWxCode(){
      let that = this;
      that.codeUrl = that.$store.state.baseURL + "#/parClear_cost?id=" + that.$store.getters.getSelectConsumptionCarId;
      /*let url = that.$store.state.baseURL + "#/parClear_cost";
      let params = {
        app_id: that.$store.state.app_id,
        redirect_uri: that.$tool.toURLEncode(url),
        state: 1
      };
      that.codeUrl = getWxCode(params);*/
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-div-card{
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  .span1{
    width: 50%;
    height: 28px;
    line-height: 28px;
    color: #3E4576;
    font-size: 16px;
  }
  .span2{
    width: 50%;
    height: 28px;
    line-height: 28px;
    color: #8B8FAD;
    font-size: 14px;
  }
}
</style>